<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col sm:w-full md:w-1/2">
          <vs-input class="w-full" name="code" label="Code" v-model="code"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <browse-contact :isvendor="true" v-validate="'required'" name="vendor_id" data-vv-as="Default vendor" label="Default Vendor" v-model="vendor_id"></browse-contact>
          <span class="text-danger text-sm" v-show="errors.has('vendor_id')">{{errors.first('vendor_id')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <small>Operational Cost</small>
          <vs-checkbox v-model="is_operational"></vs-checkbox>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <small>BBM Cost</small>
          <vs-checkbox v-model="is_bbm"></vs-checkbox>
        </div>
      </div>
      <div class="vx-row mb-5" v-show="is_bbm">
        <div class="vx-col w-1/3">
          <vs-input class="w-full" type="number" @keyup="calculateTotal()" @focus="$event.target.select()" name="qty" label="Qty" v-model="qty"></vs-input>
        </div>
        <div class="vx-col w-1/2">
          <vs-input class="w-full" type="number" @keyup="calculateTotal()" @focus="$event.target.select()" name="price" label="Price" v-model="price"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col sm:w-full md:w-1/2">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" name="total_price" label="Std. Price" v-model="total_price"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('total_price')">{{errors.first('total_price')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <small>PPN 10%</small>
          <vs-checkbox v-model="is_ppn"></vs-checkbox>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" name="description" label="Description" v-model="description"></vs-input>
        </div>
      </div>

      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import BrowseContact from '@/views/browse_input/Contact.vue'

export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  components:{
    BrowseContact
  },
  data(){
    return {
      code: "",
      name: "",
      vendor_id: null,
      is_operational: true,
      is_bbm: false,
      is_ppn: false,
      qty: 0,
      price: 0,
      total_price: 0,
      description: null,
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'costs/store',
      dispatchUpdate: 'costs/update',
      dispatchShow: 'costs/show'
    }),
    calculateTotal(){
      if(!this.is_bbm) {
            this.qty = 1
      } 
      this.total_price = parseFloat(this.qty) * parseFloat(this.price)
    },
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          code: this.code,
          name: this.name,
          vendor_id: this.vendor_id,
          is_operational: this.is_operational,
          is_bbm: this.is_bbm,
          is_ppn: this.is_ppn,
          qty: this.qty,
          price: this.price,
          total_price: this.total_price,
          description: this.description,
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.code = data.code
      this.name = data.name
      this.vendor_id = data.vendor_id
      this.qty = data.qty
      this.price = data.price
      this.total_price = data.total_price
      this.description = data.description
      this.is_operational = data.is_operational
      this.is_bbm = data.is_bbm
      this.is_ppn = data.is_ppn
      this.calculateTotal()
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>